import React, { useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { AccountRole } from '@7chairs/types';
import { Navigator } from '../components/navigator/index';
import { AppGateway } from './AppGateway';
import Notice from '../components/Notice';
import MobileAppBanner from '../components/MobileAppBanner';
import { FirstMeetingQuestionnaire } from '../screens/firstMeetingQuestionnaire/FirstMeetingQuestionnaire';
import { GroupSpaceContainer, GroupSpaceTab } from '../screens/groupSpace/GroupSpaceContainer';
import { AuthContainer } from '../screens/auth/AuthContainer';
import MyGroupsContainer from '../screens/myGroups/MyGroupsContainer';
import ProfileContainer from '../screens/profile/ProfileContainer';
import PaymentContainer from '../screens/payment/PaymentContainer';
import PurchaseContainer from '../screens/purchase/PurchaseContainer';
import NotificationsSettingsContainer from '../screens/notificationsSettings/NotificationsSettingsContainer';
import ExerciseContainer from '../screens/exercise/ExerciseContainer';
import { Wrapper } from '../components/designSystem';
import { InAppLinkHandler } from '../components/inAppLinkHandler/InAppLinkHandler';
import { ExplainProblem } from '../screens/profile/assistance/ExplainProblem';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '5px',
    bottom: '5px',
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      left: 100,
      right: 100,
    },
    [theme.breakpoints.up('md')]: {
      left: 150,
      right: 150,
    },
    [theme.breakpoints.up('lg')]: {
      left: 300,
      right: 300,
    },
    margin: '0 auto',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
  },
}));

export const RootNavigation = () => {
  const classes = useStyles();

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, [
    window.navigator.userAgent,
  ]);

  return (
    <>
      <Router basename="/app">
        <Navigator>
          <AppGateway>
            <Switch>
              {/* new design */}
              <Route path="/group/:groupInstanceId" exact>
                <GroupSpaceContainer tab={GroupSpaceTab.GroupSpace} />
              </Route>
              <Route path="/conversations/:groupInstanceId">
                <GroupSpaceContainer tab={GroupSpaceTab.Conversations} />
              </Route>
              {/* old design */}
              <Route path="/i/:token">
                <Paper className={classes.root}>
                  <AuthContainer />
                </Paper>
              </Route>
              <Route path="/login">
                <Paper className={classes.root}>
                  <AuthContainer />
                </Paper>
              </Route>
              <Route path="/profile/avatar-picking">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Create your profile',
                    withBackNav: true,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path={['/profile/items', '/profile/items?:itemsQuery']}>
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit profile',
                    withBackNav: true,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path={['/profile/items/tag-options', '/profile/items/tag-options?:tagOptionsQuery']}>
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit profile',
                    withBackNav: true,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/profile/items/image-builder">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit profile',
                    withBackNav: true,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/profile/items/image-builder/avatar">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit profile',
                    withBackNav: true,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/profile/items/image-builder/picture">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit profile',
                    withBackNav: true,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/profile/image-upload">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit profile',
                    withBackNav: true,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/profile">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    mainLogo: true,
                    withSettings: true,
                    optBackNav: ({ role }) => role === AccountRole.Member,
                  }}
                >
                  <ProfileContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/mygroups">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    mainLogo: true,
                  }}
                >
                  <MyGroupsContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/group/:groupId/first-meeting-questionnaire">
                <Paper className={classes.root}>
                  <FirstMeetingQuestionnaire />
                </Paper>
              </Route>
              {/* legacy payments start */}
              <Route path="/payment/add">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: "Let's get started",
                    withBackNav: true,
                  }}
                >
                  <PaymentContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/payment/edit">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit Payment Method',
                    withBackNav: true,
                  }}
                >
                  <PaymentContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/payment/cancel">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Manage subscription status',
                    withBackNav: true,
                  }}
                >
                  <PaymentContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/payment/cancel-confirmation">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Manage subscription status',
                    withBackNav: false,
                  }}
                >
                  <PaymentContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/payment/explain-problem">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Explain problem',
                    withBackNav: true,
                  }}
                >
                  <ExplainProblem />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/payment/confirmation">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Payment Received',
                    withBackNav: true,
                  }}
                >
                  <PaymentContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/payment">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Plan and Payments',
                    withBackNav: true,
                  }}
                >
                  <PaymentContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              {/* legacy payments end */}

              {/* voices purchases start */}
              <Route path="/purchase/add">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: "Let's get started",
                    withBackNav: true,
                  }}
                >
                  <PurchaseContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>

              <Route path="/purchase/edit">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Edit Payment Method',
                    withBackNav: true,
                  }}
                >
                  <PurchaseContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>

              <Route path={['/purchase/cancel']}>
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Manage purchase status',
                    withBackNav: true,
                  }}
                >
                  <PurchaseContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>

              <Route path={['/purchase/cancel-confirmation', '/purchase/cancel-confirmation?:cancelConfirmationQuery']}>
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Manage purchase status',
                    withBackNav: false,
                  }}
                >
                  <PurchaseContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>

              <Route path="/purchase/confirmation">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Payment Received',
                    withBackNav: true,
                  }}
                >
                  <PurchaseContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>

              <Route path="/purchase">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Plan and Payments',
                    withBackNav: true,
                  }}
                >
                  <PurchaseContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              {/* voices purchases end */}

              <Route path="/notifications_settings">
                <Wrapper.WithHeaderFromPreviousDesignSystem
                  topBarProps={{
                    isMobileWebview,
                    title: 'Notifications',
                    withBackNav: true,
                  }}
                >
                  <NotificationsSettingsContainer />
                </Wrapper.WithHeaderFromPreviousDesignSystem>
              </Route>
              <Route path="/exercise/:exerciseId">
                <Paper className={classes.root}>
                  <ExerciseContainer />
                </Paper>
              </Route>
              <Route path="/" />
            </Switch>
          </AppGateway>
        </Navigator>
      </Router>
      <Notice />
      <InAppLinkHandler />
      <MobileAppBanner isMobileWebview={isMobileWebview} />
    </>
  );
};
