import { getColor } from '@7chairs/frontend';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CtaButton } from '../../components/buttons';
import { NavigationContext } from '../../components/navigator';
import { Header, Paragraph } from '../../components/Text';
import { getPurchaseInfo } from '../../store/ducks/purchase';
import { trackEvent } from '../../utils/analyticsEvent';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '32px auto',
    gap: '32px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      margin: '32px 16px',
    },
  },
  section: {
    textAlign: 'center',
  },
  ctaBtn: {
    marginTop: '16px',
    cursor: 'pointer',
    backgroundColor: getColor('RED_100'),
    '&:disabled': {
      backgroundColor: getColor('BLACK_40'),
      color: getColor('WHITE_100'),
      cursor: 'default',
    },
  },
}));

function CancelSubscription({ cancelSubscriptionRequest, billing, getPaymentDetails }) {
  const navCtx = useContext(NavigationContext);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (!billing) {
      setIsSubmitButtonDisabled(true);
      setLoadingData(true);
      getPaymentDetails()
        .then(() => {
          setIsSubmitButtonDisabled(false);
          setLoadingData(false);
        })
        .catch(() => {
          navCtx.replaceScreen(`/profile`);
        });
    }
  }, []);

  const handleCancelSubscription = async () => {
    try {
      trackEvent('click cancel subscription');
      setIsSubmitButtonDisabled(true);
      const result = await cancelSubscriptionRequest();

      const isPaymentAlreadyCancelled = result?.payload?.data?.cancelPayment?.cancelled;

      if (isPaymentAlreadyCancelled) {
        // refresh purchase data after cancelling purchase
        await dispatch(getPurchaseInfo());
      }

      navCtx.replaceScreen(
        `/purchase/cancel-confirmation?already-cancelled=${result?.payload?.data?.cancelPayment?.cancelled ?? false}`
      );
    } catch (error) {
      setIsSubmitButtonDisabled(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      {loadingData && <CircularProgress color="primary" />}
      {!loadingData && (
        <>
          <Header className={classes.section}>We're Sad to See You Go 💔</Header>
          <Paragraph className={classes.section}>
            We know that sometimes plans change, and that's okay. If you change your mind, we're here whenever you're
            ready to come back to your healing journey.
          </Paragraph>
          <Paragraph className={classes.section}>You'll always have a place with us.</Paragraph>
          <CtaButton className={classes.ctaBtn} disabled={isSubmitButtonDisabled} onClick={handleCancelSubscription}>
            {billing?.inTrialPeriod && billing?.isOneTimePurchase ? 'Cancel trial' : 'Cancel my subscription'}
          </CtaButton>
        </>
      )}
    </div>
  );
}

export default CancelSubscription;
