import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, CircularProgress } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import clsx from 'clsx';
import { trackEvent } from '../../utils/analyticsEvent';
import { NavigationContext } from '../../components/navigator';
import PurchaseDetails from './PurchaseDetails';
import PurchaseBillingCycle from './PurchaseBillingCycle';
import { Label1, SubHeader } from '../../components/Text';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';
import { Paragraph } from '../../components/Text';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: OLD_COLORS.WHITE,
    overflow: 'auto',
    flexWrap: 'nowrap',
    position: 'relative',
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 80,
  },
  paymentDetailsWrapper: {
    marginBottom: OLD_SIZES.xl + OLD_SIZES.m,
  },
  contentWrapper: {
    borderBottom: `1px solid ${OLD_COLORS.GRAY_300}`,
    cursor: 'pointer',
  },
  borderTopSection: {
    borderTop: `1px solid ${OLD_COLORS.GRAY_300}`,
  },
  billingSum: {
    fontSize: 16,
    fontWeight: '600',
    color: OLD_COLORS.BLACK,
    opacity: 0.5,
  },
  bigGrid: {
    padding: `${OLD_SIZES.l}px ${OLD_SIZES.xl}px`,
    alignSelf: 'center',
  },
  smallGrid: {
    textAlign: 'center',
    padding: `${OLD_SIZES.l}px ${OLD_SIZES.xl}px`,
  },
  successMessageRequestDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: OLD_SIZES.xl + OLD_SIZES.l,
    [theme.breakpoints.down('sm')]: {
      marginTop: OLD_SIZES.l,
    },
  },
  successMessageRequestContent: {
    backgroundColor: OLD_COLORS.GREEN_500,
    borderRadius: '100px',
    padding: `${OLD_SIZES.s}px ${OLD_SIZES.l}px`,
  },
  successContentRow: {
    display: 'flex',
    alignItems: 'center',
  },
  successCheckedIcon: {
    color: OLD_COLORS.WHITE,
    marginRight: OLD_SIZES.xs,
  },
  paypalButtonContainer: {
    width: '150px',
  },
  paypalContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    padding: '24px 32px',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  contactUsContainer: {
    padding: '24px',
  },
}));

function Purchase({
  cardDetails,
  billing,
  voicesPurchaseCancelPaymentRequest,
  displaySuccessEdit,
  getPaymentDetails,
  reenableSubscription,
  closeSuccessEdit,
}) {
  const navCtx = useContext(NavigationContext);

  const classes = useStyles();

  const [submitted, setSubmitted] = useState(false);

  const { amount = 0, isShortPlan, inTrialPeriod, isOneTimePurchase } = billing || {};

  const isOneTimeTrialPurchase = isOneTimePurchase && inTrialPeriod;
  const canCancelSubscription = (!isOneTimePurchase && (!isShortPlan || inTrialPeriod)) || isOneTimeTrialPurchase;
  const canEditPaymentMethod = cancelPaymentStatus !== 'new' && !isOneTimePurchase;

  const cancelPaymentStatus = voicesPurchaseCancelPaymentRequest?.status;

  const buttonLabel = isOneTimePurchase
    ? 'Cancel trial'
    : cancelPaymentStatus === 'new'
    ? 'Reactivate Subscription'
    : 'Cancel my subscription';

  useEffect(() => {
    if (!cardDetails || !billing) {
      setSubmitted(true);
      getPaymentDetails()
        .then(() => {
          setSubmitted(false);
        })
        .catch(() => navCtx.popScreen());
    }
  }, []);

  useEffect(() => {
    if (displaySuccessEdit) {
      setTimeout(() => {
        closeSuccessEdit();
      }, 6000);
    }
  }, [displaySuccessEdit]);

  const onCancelClick = () => {
    if (cancelPaymentStatus === 'new' && !isOneTimeTrialPurchase) {
      trackEvent('click re-enable subscription');
      setSubmitted(true);
      reenableSubscription()
        .then(() => setSubmitted(false))
        .catch(() => setSubmitted(false));
    } else {
      trackEvent('click open cancel subscription');
      navCtx.pushScreen(`/purchase/cancel`);
    }
  };

  return (
    <Grid className={classes.root}>
      {submitted && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" />
        </div>
      )}

      <div className={classes.paymentDetailsWrapper}>
        <PurchaseDetails card={cardDetails || {}} />
      </div>

      {!submitted && !isShortPlan && !isOneTimePurchase && <PurchaseBillingCycle billingDetails={billing || {}} />}

      {(amount > 0 || canCancelSubscription) && (
        <>
          {canEditPaymentMethod && (
            <div className={clsx(classes.contentWrapper, classes.borderTopSection)}>
              <Grid
                container
                onClick={() => {
                  trackEvent('click open edit payment');
                  navCtx.pushScreen('/purchase/edit');
                }}
              >
                <Grid item xs={9} className={classes.bigGrid}>
                  <Label1 fontWeight="500">Edit Payment Method</Label1>
                </Grid>
                <Grid item xs={3} className={classes.smallGrid}>
                  <IconButton size="small" style={{ color: OLD_COLORS.BLACK }}>
                    <ChevronRightIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          )}
          {!submitted && canCancelSubscription && (
            <div className={classes.contentWrapper}>
              <Grid container onClick={onCancelClick}>
                <Grid item xs={9} className={classes.bigGrid}>
                  <Label1 color={OLD_COLORS.BLACK} fontWeight="500">
                    {buttonLabel}
                  </Label1>
                </Grid>
                <Grid item xs={3} className={classes.smallGrid}>
                  <IconButton size="small" style={{ color: OLD_COLORS.BLACK }}>
                    <ChevronRightIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      {cancelPaymentStatus === 'new' && (
        <div className={classes.successMessageRequestDiv}>
          <div className={classes.successMessageRequestContent}>
            <SubHeader color={OLD_COLORS.WHITE}>Your cancellation request was sent</SubHeader>
          </div>
        </div>
      )}
      {displaySuccessEdit && (
        <div className={classes.successMessageRequestDiv}>
          <div className={clsx(classes.successMessageRequestContent, classes.successContentRow)}>
            <CheckCircleOutlineOutlinedIcon className={classes.successCheckedIcon} />
            <SubHeader color={OLD_COLORS.WHITE}>payment method successfully changed</SubHeader>
          </div>
        </div>
      )}
      <div className={classes.contactUsContainer}>
        <Paragraph>
          If you have a question{' '}
          <a href="https://circlesup.com/contact-us/" target="_blank">
            please contact us
          </a>
          . We'll get back to you as soon as we can – generally within one business day.
        </Paragraph>
      </div>
    </Grid>
  );
}

export default Purchase;
