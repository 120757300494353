import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Header, Paragraph } from '../../components/Text';
import { Button } from '@7chairs/frontend';
import { NavigationContext } from '../../components/navigator';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '32px auto',
    gap: '32px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      margin: '32px 16px',
    },
  },
  section: {
    textAlign: 'center',
  },
  ctaBtn: {
    marginTop: '16px',
    cursor: 'pointer',
    width: '160px',
  },
  contactUsParagraph: {
    textAlign: 'center',
  },
}));

function CancelSubscriptionConfirmation() {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);

  const queryParams = new URLSearchParams(location.search);
  const alreadyCancelled = queryParams.get('already-cancelled') === 'true';

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, [
    window.navigator.userAgent,
  ]);

  const onButtonClick = () => {
    if (isMobileWebview) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'close', alreadyCancelled }));
      return;
    }

    if (alreadyCancelled) {
      navCtx.replaceScreen(`/profile`);
    } else {
      navCtx.replaceScreen(`/purchase`);
    }
  };

  return (
    <div className={classes.wrapper}>
      {!alreadyCancelled && (
        <>
          <Header className={classes.section}>Your cancellation request has been sent</Header>
          <Paragraph className={classes.section}>
            Please allow up to 3 business days to process. A confirmation will be sent to your registered email. For any
            questions, reach out to support@circlesup.com — we're here to help.
          </Paragraph>
          <Button className={classes.ctaBtn} onClick={onButtonClick} size="big" variant="primary">
            Got it
          </Button>
        </>
      )}
      {alreadyCancelled && (
        <>
          <Header className={classes.section}>Your purchase has been cancelled</Header>
          <Paragraph className={classes.contactUsParagraph}>
            If you have a question{' '}
            <a href="https://circlesup.com/contact-us/" target="_blank">
              please contact us
            </a>
            . We'll get back to you as soon as we can – generally within one business day.
          </Paragraph>

          <Button className={classes.ctaBtn} onClick={onButtonClick} size="big" variant="primary">
            Got it
          </Button>
        </>
      )}
    </div>
  );
}

export default CancelSubscriptionConfirmation;
