import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { setCalendlyPopUpData, setDialogProps } from '../../store/ducks/root';
import { openNotice } from '../../store/ducks/notice';
import {
  getClientSecret,
  updatePaymentMethod,
  getPaymentDetails,
  cancelSubscriptionRequest,
  reenableSubscription,
  closeSuccessPopup,
  closeSuccessEdit,
} from '../../store/ducks/purchase';
import Purchase from './Purchase';
import EditPurchase from './EditPurchase';
import CancelSubscription from './CancelSubscription';
import Confirmation from './Confirmation';
import CancelSubscriptionConfirmation from './CancelSubscriptionConfirmation';

function PurchaseContainer(props) {
  const location = useLocation();

  const baseUrl = '/purchase';

  if (location.pathname === `${baseUrl}/add`) {
    const token = window.localStorage.authToken;
    // @ts-ignore
    window.location = `${PAYMENT_URL}/?accessToken=${token}`;
    return;
  }

  return (
    <Switch>
      <Route path={`${baseUrl}/edit`}>
        <EditPurchase {...props} />
      </Route>
      <Route path={[`${baseUrl}/cancel`]}>
        <CancelSubscription {...props} />
      </Route>
      <Route path={[`${baseUrl}/cancel-confirmation`, `${baseUrl}/cancel-confirmation?:cancelConfirmationQuery`]}>
        <CancelSubscriptionConfirmation />
      </Route>
      <Route path={`${baseUrl}/confirmation`}>
        <Confirmation {...props} />
      </Route>
      <Route path={baseUrl}>
        <Purchase {...props} />
      </Route>
    </Switch>
  );
}

function mapState(state) {
  return {
    plans: state.purchase.plans,
    currentPlan: state.purchase.currentPlan,
    discount: state.purchase.discount,
    clientSecret: state.purchase.clientSecret,
    cardDetails: state.purchase.cardDetails,
    billing: state.purchase.billing,
    displaySuccessPopup: state.purchase.displaySuccessPopup,
    displaySuccessEdit: state.purchase.displaySuccessEdit,
    voicesPurchaseCancelPaymentRequest: state.account.voicesPurchaseCancelPaymentRequest,
    loggedWithToken: state.root.loggedWithToken,
  };
}

export default connect(mapState, {
  getClientSecret,
  updatePaymentMethod,
  getPaymentDetails,
  cancelSubscriptionRequest,
  reenableSubscription,
  closeSuccessPopup,
  closeSuccessEdit,
  openNotice,
  setDialogProps,
  setCalendlyPopUpData,
})(PurchaseContainer);
